import React, { useState } from "react";
import {
  TableRow,
  TableCell,
  IconButton,
  Collapse,
  Box,
  Typography,
  Table,
  TableBody,
  TableHead,
} from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";

/** Rendering row contents */

interface RowProps {
  row: {
    key: string;
    position: any;
    matchedDnr: any[];
  };
  columns: string[];
  highlighted: boolean;
  onHover: (key: string | null) => void;
  isFirstRow: boolean;
}

const stickyFirstRowCellStyles: React.CSSProperties = {
  position: "sticky" as "sticky",
  top: 30,
  backgroundColor: "white",
  zIndex: 1,
};

const getStatusColor = (status: string) => {
  if (status === "CONFIRMED") return "green";
  else if (status === "STUDY") return "tan";
  else if (status === "RECIEVED") return "yellow";
  else return;
};

const Row: React.FC<RowProps> = ({
  row,
  columns,
  highlighted,
  onHover,
  isFirstRow,
}) => {
  const { key, position, matchedDnr } = row;
  const [open, setOpen] = useState(false);
  const positionData = Array.isArray(position.data) ? position.data : [];

  const rowCellStyles = isFirstRow ? stickyFirstRowCellStyles : {};

  return (
    <React.Fragment>
      <TableRow
        sx={{ "& > *": { borderBottom: "unset" }, height: "1vh" }}
        onMouseEnter={() => onHover(key)}
        onMouseLeave={() => onHover(null)}
        style={{ backgroundColor: highlighted ? "#fffe78" : "inherit" }}
      >
        <TableCell style={rowCellStyles}>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell
          component="th"
          scope="row"
          style={{
            ...rowCellStyles,
            maxWidth: 200,
            overflow: "hidden",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
          }}
        >
          {key}
        </TableCell>
        {columns.map((month, index) => (
          <TableCell
            key={index}
            sx={{ fontSize: "0.8rem", padding: "8px", ...rowCellStyles }}
          >
            {positionData[index] || "0"}
          </TableCell>
        ))}
      </TableRow>
      <TableRow>
        <TableCell
          style={{ paddingBottom: 0, paddingTop: 0 }}
          colSpan={columns.length + 2}
        >
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1 }}>
              <Typography variant="h6" gutterBottom component="div">
                DNR Details
              </Typography>
              {matchedDnr.length ? (
                <Table size="small" aria-label="purchases">
                  <TableHead>
                    <TableRow>
                      <TableCell>Status</TableCell>
                      <TableCell>TP</TableCell>
                      <TableCell>MW Req</TableCell>
                      <TableCell>MW Grant</TableCell>
                      <TableCell>POR</TableCell>
                      <TableCell>POD</TableCell>
                      <TableCell>Increment</TableCell>
                      <TableCell>Start Time</TableCell>
                      <TableCell>Stop Time</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {matchedDnr.map((dnr, index) => (
                      <TableRow key={index} sx={{ height: 30 }}>
                        <TableCell
                          style={{ color: getStatusColor(dnr.status) }}
                        >
                          {dnr.status}
                        </TableCell>
                        <TableCell>{dnr.tp}</TableCell>
                        <TableCell>{dnr.mw_Req}</TableCell>
                        <TableCell>{dnr.mw_Grant}</TableCell>
                        <TableCell>{dnr.POR}</TableCell>
                        <TableCell>{dnr.POD}</TableCell>
                        <TableCell>{dnr.increment}</TableCell>
                        <TableCell>{dnr.start_time}</TableCell>
                        <TableCell>{dnr.stop_time}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              ) : (
                <Typography>No DNR data available</Typography>
              )}
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
};

export default Row;
