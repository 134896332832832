export const getLocalDateStringFromDate = (date: Date) => {
  const offset = date.getTimezoneOffset();
  const shiftedDate = new Date(date.getTime() - offset * 60 * 1000);
  return shiftedDate.toISOString().split("T")[0];
};

export const getUTCDateStringLocalOffset = (date: Date) => {
  // 2023-08-07T00:00:00-0600
  const date2 = new Date(date);

  const utcOutput =
    date2.getFullYear().toString() +
    "-" +
    (date2.getMonth() + 1).toString().padStart(2, "0") +
    "-" +
    date2.getDate().toString().padStart(2, "0") +
    "T" +
    date2.getHours().toString().padStart(2, "0") +
    ":" +
    date2.getMinutes().toString().padStart(2, "0") +
    ":" +
    date2.getSeconds().toString().padStart(2, "0") +
    date2.toString().substring(28, 33);
  //This is kind of hacky but the date already has
  //the UTC offset for denver that we can grab via substring

  return utcOutput;
};

export const getMtHourFromUtcDate = (utcDatetime: string) => {
  const hour = new Date(utcDatetime).toLocaleString("en-US", {
    hour: "2-digit",
    hour12: false,
    timeZone: "America/Denver",
  });
  // Convert string to number
  return +hour;
};

export const getDefaultDate = (days: number) => {
  const date = new Date(Date.now() + days * 24 * 60 * 60 * 1000);
  date.setHours(0, 0, 0, 0);
  return date;
};

export const getCurrentHourlyNow = () => {
  const now = new Date();
  now.setMinutes(0);
  now.setSeconds(0);
  now.setMilliseconds(0);
  return now;
};

export const dateToDatetimeString = (date: Date) => {
  let hours = date.getHours();
  const ampm = hours >= 12 ? "PM" : "AM";
  hours = hours % 12;
  // 0 hour should be 12 hour
  hours = hours ? hours : 12;
  return `${date.toLocaleDateString()} ${hours}${ampm}`;
};

export const getRoundedUpHourNow = () => {
  const now = new Date();
  const hour = now.getHours();
  const roundedUpHour = hour + 1;
  now.setHours(roundedUpHour, 0, 0, 0);
  return now;
};
