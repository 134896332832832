import React from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@mui/material";
import Row from "./PositionRow";

/** Defining position table components */

interface PositionsTableProps {
  combinedData: any[];
  columns: string[];
  highlightedRow: string | null;
  onRowHover: (key: string | null) => void;
}

const tableContainerStyles: React.CSSProperties = {
  maxHeight: "25vh",
  overflow: "auto",
  width: "85vw",
  marginTop: 16,
  position: "relative",
};

const stickyHeaderCellStyles: React.CSSProperties = {
  position: "sticky" as "sticky",
  top: 0,
  backgroundColor: "white",
  zIndex: 2,
};

const PositionsTable: React.FC<PositionsTableProps> = ({
  combinedData,
  columns,
  highlightedRow,
  onRowHover,
}) => {
  return (
    <div>
      <TableContainer component={Paper} style={tableContainerStyles}>
        <Table aria-label="collapsible table" size="small">
          <TableHead style={{ height: 20 }}>
            <TableRow style={{ height: 30 }}>
              <TableCell style={stickyHeaderCellStyles} />
              <TableCell style={{ ...stickyHeaderCellStyles, maxWidth: 10 }}>
                `
              </TableCell>
              {columns.map((month, index) => (
                <TableCell key={index} style={stickyHeaderCellStyles}>
                  {month}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {combinedData.map((row, rowIndex) => (
              <Row
                key={row.key}
                row={row}
                columns={columns}
                highlighted={highlightedRow === row.key}
                onHover={onRowHover}
                isFirstRow={rowIndex === 0}
              />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default PositionsTable;
