import React, { useState } from "react";
import { Polyline, Marker, Popup } from "react-leaflet";
import L, { LatLngExpression } from "leaflet";
import ElectricMeterIcon from "@mui/icons-material/ElectricMeter";
import WindPowerIcon from "@mui/icons-material/WindPower";
import SolarPowerIcon from "@mui/icons-material/SolarPower";
import HeatPumpIcon from "@mui/icons-material/HeatPump";
import { renderToStaticMarkup } from "react-dom/server";

/** Drawing transmission lines and markers on map */

interface TransmissionLinesProps {
  paths: { [key: string]: string[] }[];
  substations: { [key: string]: [number, number] };
  highlightedRow: string | null;
}

const TransmissionLines: React.FC<TransmissionLinesProps> = ({
  paths,
  substations,
  highlightedRow,
}) => {
  const [hoveredPath] = useState<string | null>(null);

  const blackCircleIcon = L.divIcon({
    className: "custom-marker-icon",
    html: renderToStaticMarkup(
      <svg
        width="20"
        height="25"
        viewBox="0 0 20 30"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle cx="7" cy="10" r="6" fill="black" />
      </svg>,
    ),
  });

  const electricMeterIcon = L.divIcon({
    className: "custom-marker-icon",
    html: renderToStaticMarkup(
      <ElectricMeterIcon
        style={{
          color: "#350201",
          fontSize: "40px",
          position: "relative",
          bottom: "15px",
        }}
      />,
    ),
  });

  const callOptionIcon = L.divIcon({
    className: "custom-marker-icon",
    html: renderToStaticMarkup(
      <HeatPumpIcon
        style={{
          color: "#c22117",
          fontSize: "40px",
          position: "relative",
          bottom: "15px",
          right: "10px",
        }}
      />,
    ),
  });

  const windPowerIcon = L.divIcon({
    className: "custom-marker-icon",
    html: renderToStaticMarkup(
      <WindPowerIcon
        style={{
          color: "#5cd98b",
          fontSize: "40px",
          position: "relative",
          bottom: "15px",
        }}
      />,
    ),
  });

  const solarPowerIcon = L.divIcon({
    className: "custom-marker-icon",
    html: renderToStaticMarkup(
      <SolarPowerIcon style={{ color: "#bc834c", fontSize: "40px" }} />,
    ),
  });

  const getMarkerIcon = (point: string) => {
    if (point === "BLKW" || point === "LAMR" || point === "SCSE") {
      return electricMeterIcon;
    } else if (point === "Panorama" || point === "Bronco") {
      return windPowerIcon;
    } else if (
      ["Ash Mesa", "CORE", "Garnet Mesa", "Shallow Basket"].includes(point)
    ) {
      return solarPowerIcon;
    } else if (point === "CRG") {
      return callOptionIcon;
    }
    return blackCircleIcon; // Default icon for other substations
  };

  // Function to draw markers for substations included in the paths
  const drawMarkers = () => {
    const uniquePositions = new Set<string>();
    const markers: JSX.Element[] = [];

    paths.forEach((pathObj) => {
      const path = Object.values(pathObj)[0];
      path.forEach((point) => {
        const position = substations[point];
        if (position) {
          const positionKey = position.toString();
          uniquePositions.add(positionKey);
          const markerIcon = getMarkerIcon(point);
          markers.push(
            <Marker key={positionKey} position={position} icon={markerIcon}>
              <Popup>
                <h3>{point}</h3>
              </Popup>
            </Marker>,
          );
        }
      });
    });

    return markers;
  };

  // drawing the highlighted path from which row is being hovered over
  const drawHighlightedPath = () => {
    return paths.map((pathObj) => {
      const pathKey = Object.keys(pathObj)[0];
      const path = pathObj[pathKey];
      const positions: LatLngExpression[] = path
        .map((point) => substations[point])
        .filter(Boolean);
      const isHighlighted = highlightedRow?.trim() === pathKey.trim();
      const isHovered = hoveredPath === pathKey;

      if (isHighlighted || isHovered) {
        return (
          <Polyline
            key={`highlighted-polyline-${pathKey}`}
            positions={positions}
            color="red"
            weight={6}
          >
            <Popup>
              <h3>{pathKey}</h3>
            </Popup>
          </Polyline>
        );
      }
      return null;
    });
  };

  // drawing all other paths not being hovered over, but still are in the position
  const drawOtherPaths = () => {
    return paths.map((pathObj) => {
      const pathKey = Object.keys(pathObj)[0];
      const path = pathObj[pathKey];
      const positions: LatLngExpression[] = path
        .map((point) => substations[point])
        .filter(Boolean);
      const isHighlighted = highlightedRow?.trim() === pathKey.trim();
      const isHovered = hoveredPath === pathKey;

      if (!isHighlighted && !isHovered) {
        return (
          <Polyline
            key={`polyline-${pathKey}`}
            positions={positions}
            color="blue"
            weight={3}
          >
            <Popup>
              <h3>{pathKey}</h3>
            </Popup>
          </Polyline>
        );
      }
      return null;
    });
  };

  return (
    <>
      {drawHighlightedPath()}
      {drawOtherPaths()}
      {drawMarkers()}
    </>
  );
};

export default TransmissionLines;
